<template>
  <div>
    <div class="contain1">
      <div>
        任务类型
        <el-select
          clearable
          filterable
          size="mini"
          v-model="search.taskType"
          class="m1150 select230"
          placeholder="请选择"
          @change="inquire"
        >
          <el-option
            v-for="item in taskType"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
        年份
        <el-select
          clearable
          filterable
          size="mini"
          v-model="search.taskYear"
          class="m1150 select230"
          placeholder="请选择"
          @change="inquire"
        >
          <el-option
            v-for="item in taskType"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
        模板
        <el-select
          clearable
          filterable
          size="mini"
          v-model="search.tplId"
          class="m1150 select230"
          placeholder="请选择"
          @change="inquire"
        >
          <el-option
            v-for="item in searchMubanList"
            :key="item.id"
            :label="item.tplName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        安检员
        <el-select
          clearable
          filterable
          size="mini"
          v-model="search.userId"
          class="m1150 select230"
          placeholder="请选择"
          @change="inquire"
        >
          <el-option
            v-for="item in checkerList"
            :key="item.id"
            :label="item.realname"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <br />
        状态
        <el-select
          clearable
          filterable
          size="mini"
          v-model="search.finishType"
          class="m1150 select230"
          placeholder="请选择"
          @change="inquire"
        >
          <el-option
            v-for="item in searchMubanList"
            :key="item.id"
            :label="item.tplName"
            :value="item.id"
          >
          </el-option>
        </el-select>

        任务名称
        <el-input
          size="mini"
          type="text"
          v-model="search.taskName"
          placeholder="请输入任务名称"
          class="m1150 input340"
        ></el-input>
        <el-button
          size="mini"
          type="primary"
          class="ml20"
          icon="el-icon-search"
          @click="inquire"
          >查询</el-button
        >
        <el-button
          size="mini"
          class="ml20"
          icon="el-icon-refresh"
          @click="refresh"
          >重置</el-button
        >
      </div>
    </div>
    <div class="contain2">
      <div class="btn-top">
        <el-button
        size="mini"
        type="primary"
        @click="openForm(1)"
        >添加安检任务</el-button
        >
        <el-button size="mini" type="primary" @click="openForm(2)" class="ml20"
        >添加突击检查</el-button
        >
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#f5f7fa' }"
        border
        style="width: 100%"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="center"
          width="55"
        >
        </el-table-column>
        <el-table-column prop="taskName" align="center" label="任务名称"> </el-table-column>
        <el-table-column label="任务类型" align="center" width="100">
            <template slot-scope="{row}">
                {{row.taskType === 1 ? '正常安检' : '突击检查'}}
            </template>
        </el-table-column>
        <el-table-column prop="taskYear" align="center" label="年份" width="80"> </el-table-column>
        <el-table-column prop="tplIdName" align="center" label="模板"> </el-table-column>
        <el-table-column prop="userIdName" align="center" label="安检员"> </el-table-column>
        <el-table-column prop="houseCount" align="center" label="总户数" width="60"> </el-table-column>
        <el-table-column label="应完成数/已完成数" align="center">
            <template slot-scope="{row}">
                {{row.completeCount+'/'+row.doneCount}}
            </template>
        </el-table-column>
        <el-table-column prop="deadline" align="center" label="截止日期"> </el-table-column>
        <el-table-column prop="createTime" align="center" label="添加日期" width="150"> </el-table-column>
        <el-table-column label="状态" align="center" width="100">
            <span slot-scope="{row}" :class="statusSwitch(row.finishType).class">{{statusSwitch(row.finishType).name}}</span>
        </el-table-column>
        <el-table-column label="操作" width="180" align="left">
          <template slot-scope="scope">
            <el-button type="text" size="small"
                v-if="scope.row.taskType === 1"
                @click="editTask(scope.row,1)"
            >再次发布</el-button>
            <el-button type="text" size="small"
                v-if="scope.row.finishType == 1 || (scope.row.finishType == 2 && scope.row.taskType === 1)"
                @click="editTask(scope.row,2)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="scope.row.finishType == 1"
              style="color: #FF5400"
              @click="deleteTask(scope.row.id)"
              >删除</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="scope.row.taskType == 2 && scope.row.finishType == 2"
              style="color: #FF5400"
              @click="closeTask(scope.row.id)"
              >关闭</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="text-align: center; margin: 15px 0 100px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="search.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <el-dialog
        @closed="reset"
        :title="formTitle"
        :visible.sync="dialogFormVisible"
        width="30%"
        :close-on-click-modal="false"
      >
        <el-form
          :model="form"
          ref="ruleForm"
          label-width="100px"
          size="medium"
          :rules="rules"
        >
            <el-row :gutter="20">
                <el-col :span="20">
                    <el-form-item label="任务名称" prop="taskName">
                        <el-input
                            :disabled="form.taskType===1 && form.finishType === 2"
                            style="width: 100%"
                            v-model="form.taskName"
                            size="small"
                            placeholder="请输入任务名称"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="20">
                    <el-form-item label="年份" prop="taskYear">
                        <el-select
                            :disabled="form.taskType===1 && form.finishType === 2"
                            style="width: 100%"
                            size="small"
                            v-model="form.taskYear"
                            placeholder="请选择"
                            @change="inquire"
                            >
                            <el-option
                                v-for="item in years"
                                :key="item"
                                :label="item"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="20">
                    <el-form-item label="检查用户" prop="addr">
                        <el-select
                            :disabled="form.taskType===1 && form.finishType === 2"
                            style="width: 100%"
                            clearable
                            multiple
                            size="small"
                            v-model="form.addr"
                            placeholder="请选择"
                            @change="inquire"
                            >
                            <el-option
                                v-for="item in addrList"
                                :key="item.addrCode"
                                :label="item.addrName"
                                :value="item.addrCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="20">
                    <el-form-item label="模板" prop="tplId">
                        <el-select
                        :disabled="form.taskType===1 && form.finishType === 2"
                        style="width: 100%"
                            clearable
                            filterable
                            size="small"
                            v-model="form.tplId"
                            placeholder="请选择"
                            @change="inquire"
                            >
                            <el-option
                                v-for="item in onMubanList"
                                :key="item.id"
                                :label="item.tplName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="20">
                    <el-form-item label="安检员" prop="userId">
                        <el-select
                            style="width: 100%"
                            clearable
                            filterable
                            multiple
                            size="small"
                            v-model="form.userId"
                            placeholder="请选择"
                            @change="inquire"
                            >
                            <el-option
                                v-for="item in checkerList"
                                :key="item.id"
                                :label="item.realname"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="20">
                    <el-form-item label="截止日期" prop="deadline">
                        <el-date-picker
                        style="width: 100%"
                            v-model="form.deadline"
                            type="date"
                            size="small"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="medium" @click="saveForm">确认</el-button>
            <el-button type="info" size="medium" @click="dialogFormVisible=false">取消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
 
<script>
import {
    selectLa,
    selectTemplLa,
    saveTask,
    selectTask,
    closeTask,
    deleteTask,
    updateTask,
    selectPersonList
} from '../../RequestPort/checkTask/task.js';
import {
  selectAddrPullDown
} from "../../RequestPort/fileManage/userFile.js";
export default {
  name: "task",
  data() {
    return {
        search: {
            taskType: '',
            taskYear: '',
            tplId: '',
            userId: '',
            finishType: '',
            taskName: '',
            current: 1,
            size: 100
        },
        findObj: {},
        taskType: [{label:'正常安检',id:1},{label:'突击检查',id:2}],
        status: [{label:'未执行',id:1},{label:'进行中',id:2},{label:'已完成',id:3}],
        searchMubanList: [],
        formTitle: '',
        form: {
            taskName: '',
            taskYear: '',
            addr: [],
            tplId: '',
            userId: [],
            deadline: '',
            taskType: 1 // 任务类型 1正常安检 2突击检查
        },
        dialogFormVisible: false,
        onMubanList: [],
        years: [],
        rules: {
            taskName: [
                { required: true, message: "请输入任务名称", trigger: "blur" },
            ],
            taskYear: [
                { required: true, message: "请选择年份", trigger: "change" },
            ],
            addr: [
                { required: true, message: "请选择检查用户", trigger: "change" },
            ],
            tplId: [
                { required: true, message: "请选择模板", trigger: "change" },
            ],
            userId: [
                { required: true, message: "请选择安检员", trigger: "change" },
            ],
            deadline: [
                { required: true, message: "请选择截止日期", trigger: "blur" },
            ],
        },
        total: 0,
        editStatus: 1,
        tableData: [],
        addrList: [],
        checkerList: []
    };
  },
  mounted () {
      this.getSearchMubanList()
      this.getAddrList()
      this.getOnMubanList()
      this.objCopy()
      this.getTaskList()
      this.getCheckerList()
      const date = new Date();
        const nowYear = date.getFullYear()
      for (let i = 0; i < 5; i++){
            this.years.push(nowYear+i)
        }
  },
  methods: {
      objCopy () {
        for (let key in this.search){
            this.findObj[key] = this.search[key];
        }
      },
      refresh () {
          this.search = {
            taskType: '',
            taskYear: '',
            tplId: '',
            userId: '',
            finishType: '',
            taskName: '',
            current: 1,
            size: 100
        }
        this.objCopy()
        this.getTaskList()
      },
      indexMethod (index) {
            return (this.search.current - 1) * this.search.size + index + 1
        },
      editTask (row,type) {
          this.editStatus = type
          if (type === 1) {
              this.formTitle = '再次发布'
          } else {
              this.formTitle = row.taskType === 1 ? '编辑安检任务' : '编辑突击检查'
          }
          this.dialogFormVisible = true
          this.$nextTick(() => {
            for(let key in row) {
                  if (key === 'addr') {
                    this.form[key] = row[key].split(',')
                } else if (key === 'userId') {
                    this.form[key] = row[key].split(',').map(item => {
                          return Number(item)
                      })
                } else if (key === 'taskYear') {
                    this.form[key] = Number(row[key])
                } else {
                    this.form[key] = row[key]
                }
            }
            if (type === 1) {
                delete this.form.finishType
            }
                    console.log(this.form);
          })
      },
      deleteTask (id) {
        this.$confirm("是否确认删除此条数据", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            closeOnClickModal:false,
            type: "warning",
        }).then(() => {
            deleteTask({id}).then(res => {
                this.$message.success(res.msg);
                this.getTaskList();
            })
        });
      },
      closeTask (id) {
        this.$confirm("关闭后不能再开启，是否关闭当前任务", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            closeOnClickModal:false,
            type: "warning",
        }).then(() => {
            closeTask({ id }).then((res) => {
                if (res.code === 200) {
                    this.$message.success(res.msg);
                } else if (res.code === -1) { // 发送通知
                    this.$confirm("有待整改的工单，是否发送关闭通知", "提示", {
                        confirmButtonText: "发送通知",
                        cancelButtonText: "取消",
                        closeOnClickModal:false,
                        type: "warning",
                    }).then(tip => {
                        // 还没接口
                    })
                } else if (res.code === -301) {
                    this.$confirm("有未审核的工单，请前往处理", "提示", {
                        confirmButtonText: "确定",
                        closeOnClickModal:false,
                        type: "warning",
                    })
                }
                this.getTaskList()
            });
        });
      },
      statusSwitch (val) {
          switch (val) {
              case 1:
                  return {name: '未执行',class: 'orange'}
              case 2:
                  return {name: '进行中',class: 'green'}
              case 3:
                  return {name: '已完成',class: ''}
          }
      },
      saveForm () {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            let obj = {}
            for (let key in this.form) {
                if (key == 'addr' || key == 'userId') {
                    obj[key] = this.form[key].toString();
                } else {
                    obj[key] = this.form[key]
                }
            }
            console.log(obj);
            if (this.editStatus === 1) {
                saveTask(obj).then(res => {
                    this.$message.success(res.msg)
                    this.dialogFormVisible = false
                    this.getTaskList()
                })
            } else {
                updateTask(obj).then(res => {
                    this.$message.success(res.msg)
                    this.dialogFormVisible = false
                    this.getTaskList()
                })
            } 
          }
        })
      },
      openForm (type) {
          this.editStatus = 1
          if (type==1) {
              this.formTitle = '添加安检任务';
              this.form.taskType = 1
          } else if (type == 2) {
              this.formTitle = '添加突击检查';
              this.form.taskType = 2
          }
          this.dialogFormVisible = true
      },
      reset() {
          this.$refs.ruleForm.resetFields()
          delete this.form.taskType
          delete this.form.finishType
      },
      getTaskList () {
          selectTask(this.findObj).then((res) => {
            const { records, total } = res.data;
            this.tableData = records;
            this.total = total;
            if (records.length === 0 && res.data.pages >= 1) {
                this.search.current -= 1;
                this.findObj = this.search;
                this.getTaskList();
            }
        });
      },
      inquire () {
          this.search.current = 1
          this.objCopy()
          this.getTaskList() 
      },
    getSearchMubanList () {
        selectLa().then(res => {
            this.searchMubanList = res.data
        })
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.objCopy()
      this.getTaskList()
    },
    handleSizeChange(val) {
      this.search.current = 1
      this.search.size = val;
      this.objCopy()
      this.getTaskList()
    },
    getAddrList() {
      selectAddrPullDown().then((res) => {
        this.addrList = res.data;
      });
    },
    getOnMubanList () {
        selectTemplLa().then(res => {
            this.onMubanList = res.data
        })
    },
    getCheckerList () {
      selectPersonList().then(res => {
          this.checkerList = res.data
      })
    }
  }
};
</script>
 
<style scoped lang="less">
.orange {
    color: #FF5400;
}
.green {
    color: #05811C;
}
</style>